/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import "./less/antMotionStyle.less";
import { Button, notification, Icon, Pagination, Tooltip } from "antd";
import axios from "axios";
import { PublicKey } from "@solana/web3.js";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const { location = {} } = typeof window !== "undefined" ? window : {};
const bs58 = require("bs58");
var btoa = require("btoa");

const validateSolAddress = (address) => {
  try {
    let pubkey = new PublicKey(address);
    let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
    return isSolana;
  } catch (error) {
    return false;
  }
};

const addrSubStr = (strParam) => {
  return (
    strParam.slice(0, 6) +
    "..." +
    strParam.slice(strParam.length - 5, strParam.length - 1)
  );
};

const getUrlParams = (paramName) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(paramName);
};

const generateRandomString = (length) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const generateCodeChallenge = async (codeVerifier) => {
  let encoder = new TextEncoder();
  let data = encoder.encode(codeVerifier);
  let hashBuffer = await crypto.subtle.digest("SHA-256", data);
  let hashArray = Array.from(new Uint8Array(hashBuffer));
  let base64 = btoa(String.fromCharCode.apply(null, hashArray))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return base64;
};

function removeAllUrlParameters() {
  var url = new URL(window.location);
  url.search = "";
  window.history.replaceState(null, "", url.toString());
}

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
      drawerVisible: true,
      dashboardData: "",
      step: 1,
      pageNum: 1,
      solAddress: "",
      formatSolAddr: "",
      notConnectWallet: true,
      registInfo: {
        inviteCode: "",
      },
      estimatedRank: 0,
      invitedListInfo: {},
      refCode: "",
      registed: false,
      canReg: false,
      btnLoading: false,
      checkPass: false,
      DevicesCount: 0,
      NFTCount: 0,
      countdown: 0,
      postState: true,
      discordInfo: {},
      joinDiscordStatus: false,
      discordRoleInfo: [],
      twitterInfo: {},
      tweetCount: 0,
      following: false,
      telegramTask: false,
      checkEligibilityBtnDisable: true,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      toolTipText: () => (
        <span>
          Please review firstly
          <br />
          1. Each valid share can boost your ranking. <br />
          2. Please make sure to complete tasks using the Twitter account that
          is linked. <br />
          3. Twitter delays may cause statistical errors. Please wait for the
          system to refresh automatically.
        </span>
      ),
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    document.body.style.overflow = "auto";
    const solAddr = localStorage.getItem("solanaAddress") || "";
    const solRegAddr = localStorage.getItem("solanaRegAddress") || "";
    const dcInfo = localStorage.getItem("discordInfo") || "";
    const xInfo = localStorage.getItem("twitterInfo") || "";
    const dcRoleInfo = localStorage.getItem("discordRoleInfo") || "";
    const telTask = localStorage.getItem("telegramTask") || "";
    const discordAuthStatus = localStorage.getItem("discordAuthStatus") || "";
    const twitterAuthStatus = localStorage.getItem("twitterAuthStatus") || "";
    let codeStr = getUrlParams("ReferralCode");
    let authCodeStr = getUrlParams("code") || "";
    let twitterState = getUrlParams("state") || "";
    let codeVerifierStr = localStorage.getItem("codeVerifier") || "";
    if (codeStr && codeStr.length > 0) {
      this.setState({
        refCode: codeStr,
      });
    } else {
      this.setState({
        refCode: "",
      });
    }
    if (solAddr && solAddr.length > 0) {
      this.getNFTCount();
      this.getTwitterTaskInfo(solAddr);
      let deviceInfo = JSON.parse(localStorage.getItem("deviceInfo")) || [];
      for (let i = 0; i < deviceInfo.length; i++) {
        if (deviceInfo[i].sol == solAddr) {
          this.setState({
            DevicesCount: deviceInfo[i].value,
          });
        }
      }
      if (dcInfo && dcInfo.length > 0) {
        let formatDCInfo = JSON.parse(dcInfo);
        for (let i = 0; i < formatDCInfo.length; i++) {
          if (formatDCInfo[i].solAddress == solAddr) {
            this.setState({
              discordInfo: formatDCInfo[i].discordInfo,
              joinDiscordStatus: localStorage.getItem("joinDiscord"),
            });
            if (dcRoleInfo && dcRoleInfo.length > 0) {
              this.setState({
                discordRoleInfo: JSON.parse(dcRoleInfo),
              });
            } else {
              this.getDiscordRole(formatDCInfo[i].discordInfo);
            }
          }
        }
      }
      if (
        discordAuthStatus === false ||
        discordAuthStatus === "false" ||
        discordAuthStatus === ""
      ) {
        if (authCodeStr && authCodeStr.length > 0) {
          if (!twitterState && twitterState.length == 0) {
            this.getDiscordAuth(authCodeStr, solAddr);
          }
        }
      }
      if (xInfo && xInfo.length > 0) {
        let formatXInfo = JSON.parse(xInfo);
        for (let i = 0; i < formatXInfo.length; i++) {
          if (formatXInfo[i].solAddress == solAddr) {
            this.setState({
              twitterInfo: formatXInfo[i].twitterInfo,
            });
          }
        }
      }
      if (telTask && telTask.length > 0) {
        let formatTelTask = JSON.parse(telTask);
        for (let i = 0; i < formatTelTask.length; i++) {
          if (formatTelTask[i].solAddress == solAddr) {
            this.setState({
              telegramTask: formatTelTask[i].telegramTask,
            });
          }
        }
      }
      if (
        twitterAuthStatus === false ||
        twitterAuthStatus === "false" ||
        twitterAuthStatus == ""
      ) {
        if (
          authCodeStr &&
          authCodeStr.length > 0 &&
          twitterState &&
          twitterState.length > 0
        ) {
          this.getTwitterAuth(authCodeStr, codeVerifierStr, solAddr);
        }
      }
    }
    if (solRegAddr && solRegAddr.length > 0) {
      this.setState({
        step: 3,
        solAddress: solRegAddr,
      });
      this.getEstimatedRank(solRegAddr);
      this.getInvitedList();
      this.getRefInfo();
    } else {
      this.setState({
        step: 1,
        solAddress: solRegAddr,
      });
    }
    setInterval(() => {
      this.countdownUTC();
    }, 1000);
    window.scrollTo(0, 0);
  }
  checkAddr = async () => {
    let addr = document.getElementById("walletAddress").value;
    if (addr && addr.length > 0) {
      this.setState({
        registed: false,
        canReg: false,
      });
    }
    let isSolAddress = validateSolAddress(addr);
    if (isSolAddress) {
      this.setState({
        btnLoading: true,
      });
      axios({
        url: "/refer/api/checkIfExist?solanaAddress=" + addr,
        method: "get",
        headers: {
          "Content-Type": "multipart/form-data", //Content-Type form
          //'Content-Type': 'application/json'
        },
      }).then((res) => {
        localStorage.setItem("solanaRegAddress", addr);
        localStorage.setItem("notConnectWallet", true);
        if (res.data.data) {
          this.setState({
            registed: true,
            canReg: true,
            checkPass: true,
          });
        } else {
          this.setState({
            registed: false,
            canReg: true,
            checkPass: false,
          });
        }
        this.setState({
          btnLoading: false,
        });
      });
    }
  };
  getRefInfo = async () => {
    let addr =
      this.state.solAddress ||
      localStorage.getItem("solanaRegAddress") ||
      localStorage.getItem("solanaAddress");
    await axios.get("/refer/api/referInfo?solAddress=" + addr).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          registInfo: res.data.data,
        });
        if (this.state.refCode != this.state.registInfo.inviteCode) {
          if (document.getElementById("refCode")) {
            document.getElementById("refCode").value = this.state.refCode;
          }
        }
      }
    });
  };
  registWallet = async () => {
    let addr = document.getElementById("walletAddress").value;
    let isSolAddress = validateSolAddress(addr);
    if (isSolAddress) {
      axios({
        url: "/refer/api/register?solanaAddress=" + addr,
        method: "get",
        headers: {
          // "Content-Type": "multipart/form-data", //Content-Type form
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            localStorage.setItem("solanaRegAddress", addr);
            localStorage.setItem("notConnectWallet", true);
            this.setState({
              step: 2,
              notConnectWallet: true,
              solAddress: addr,
            });
            this.getRefInfo();
            this.getEstimatedRank(addr);
          } else if (res.data.code == 20202) {
            localStorage.setItem("solanaRegAddress", addr);
            notification.success({
              top: 70,
              duration: 1.5,
              placement: "bottomLeft",
              message: "Succress",
              description: res.data.msg,
              onClick: () => {
                console.log("close");
              },
            });
            this.setState({
              step: 1,
              registed: true,
              solAddress: addr,
            });
          } else {
            notification.error({
              top: 70,
              duration: 1.5,
              placement: "bottomLeft",
              message: "Error",
              description: res.data.msg,
              onClick: () => {
                console.log("close");
              },
            });
          }
          // this.getInvitedList();
        })
        .catch((error) => {
          notification.error({
            top: 70,
            duration: 2,
            placement: "bottomLeft",
            message: "Error",
            description: error.message,
            onClick: () => {
              console.log("close");
            },
          });
        });
    } else {
      notification.error({
        top: 70,
        duration: 1.5,
        placement: "bottomLeft",
        message: "Error",
        description: "Please enter the correct solana wallet address",
        onClick: () => {
          console.log("close");
        },
      });
      console.log("The address is NOT valid");
    }
  };
  goToProfile = () => {
    if (localStorage.getItem("solanaRegAddress")) {
      window.location.reload();
    } else {
      let addr = document.getElementById("walletAddress").value;
      localStorage.setItem("solanaRegAddress", addr);
      window.location.reload();
    }
  };
  getEstimatedRank = async (addr) => {
    let params = {
      solAddress: addr,
    };
    await axios({
      url: "/refer/api/estimatedRank",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          estimatedRank: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // get invited list
  getInvitedList = async (pageNum) => {
    let params = {
      solanaAddress:
        this.state.solAddress ||
        localStorage.getItem("solanaRegAddress") ||
        localStorage.getItem("solanaAddress"),
      pageNum: pageNum ? pageNum : this.state.pageNum,
      pageSize: 10,
    };
    await axios({
      url: "/refer/api/listInvited",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          invitedListInfo: res.data.data,
          step: 3,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // copy referral code
  copyRefCode() {
    let refCode = document.getElementById("myRefCode").value;
    const textarea = document.createElement("textarea");
    if (window.location.href.indexOf("?") > 0) {
      textarea.value =
        window.location.href.slice(0, window.location.href.indexOf("?")) +
        "?ReferralCode=" +
        refCode;
    } else {
      textarea.value =
        window.location.href.slice(0, window.location.href.length) +
        "?ReferralCode=" +
        refCode;
    }
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    notification.success({
      top: 70,
      duration: 1.5,
      placement: "bottomLeft",
      message: "Succress",
      description: "copy Succress",
      onClick: () => {
        console.log("close");
      },
    });
  }
  // Input to UpperCase
  handleInput = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z]/g, "");
    event.target.value = filteredValue.toUpperCase();
  };
  // Submit Referral code
  submitReferral = async () => {
    let refCode = document.getElementById("refCode").value;
    localStorage.setItem("submitRef", true);
    localStorage.setItem("refCode", refCode);
    localStorage.setItem("walletVisible", true);
  };
  changePage = (pageNum) => {
    this.getInvitedList(pageNum);
    this.setState({
      pageNum: pageNum,
    });
  };
  getDevicesCount = async () => {
    const solAddr = localStorage.getItem("solanaAddress");
    const params = {
      solanaAddress: solAddr,
    };
    if (this.state.postState == false) {
      if (this.state.countdown > 0 && this.state.countdown < 30) {
        return;
      }
      return;
    }
    this.setState({
      postState: false,
    });
    await axios({
      url: "/refer/api/getDevicesCount",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          DevicesCount: res.data.data,
          countdown: 0,
        });
        this.countdownFn();
        let deviceInfo = JSON.parse(localStorage.getItem("deviceInfo")) || [];
        for (let i = 0; i < deviceInfo.length; i++) {
          if (deviceInfo[i].sol == solAddr) {
            deviceInfo[i].value = res.data.data;
            localStorage.setItem("deviceInfo", JSON.stringify(deviceInfo));
            return;
          }
        }
        deviceInfo.push({
          sol: solAddr,
          value: res.data.data,
        });
        localStorage.setItem("deviceInfo", JSON.stringify(deviceInfo));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getNFTCount = async () => {
    let params = {
      solanaAddress: localStorage.getItem("solanaAddress"),
    };
    await axios({
      url: "/refer/api/getNFTCount",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          NFTCount: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  countdownFn() {
    this.timer = setInterval(() => {
      if (this.state.countdown === 30) {
        this.setState({
          postState: true,
        });
        clearInterval(this.timer);
      } else {
        this.setState((prevState) => ({
          countdown: prevState.countdown + 1,
          postState: false,
        }));
      }
    }, 1000);
  }
  ConnectDiscord = () => {
    let REDIRECT_URI = "";
    if (window.location.href.indexOf("?") > 0) {
      REDIRECT_URI = window.location.href.slice(
        0,
        window.location.href.indexOf("?")
      );
    } else {
      REDIRECT_URI = window.location.href.slice(0, window.location.href.length);
    }
    const CLIENT_ID = "1307709640825507891";
    const SCOPE = "identify connections guilds";
    const authUrl =
      "https://discord.com/oauth2/authorize?client_id=" +
      CLIENT_ID +
      "&redirect_uri=" +
      REDIRECT_URI +
      "&response_type=code&scope=" +
      SCOPE;
    window.location.href = authUrl;
  };
  joinDiscord = () => {
    const serverInviteUrl = "https://discord.com/invite/starpowernetwork";
    window.open(serverInviteUrl, "_blank");
    localStorage.setItem("joinDiscord", true);
    this.setState({
      joinDiscordStatus: true,
    });
    this.getDiscordRole();
  };
  getDiscordRole = async (dcInfo) => {
    let userId = "";
    let solAddr = localStorage.getItem("solanaAddress");
    let dcLocalInfo = JSON.parse(localStorage.getItem("discordInfo"));
    if (dcInfo && dcInfo.userId) {
      userId = dcInfo.userId;
    } else {
      if (this.state.discordInfo && this.state.discordInfo.userId) {
        userId = this.state.discordInfo.userId;
      } else {
        for (let i = 0; i < dcLocalInfo.length; i++) {
          if (dcLocalInfo[i].solAddress == solAddr) {
            userId = dcLocalInfo[i].discordInfo.userId;
          }
        }
      }
    }
    const guildId = "1181951066942754816";
    const getUrl = "/refer/api/" + guildId + "/user/" + userId;
    axios({
      url: getUrl,
      method: "get",
      params: { solanaAddress: solAddr },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          discordRoleInfo: res.data.data,
        });
        if (res.data.data && res.data.data.length > 0) {
          this.setState({
            discordRoleInfo: res.data.data,
            joinDiscordStatus: true,
          });
          localStorage.setItem("joinDiscord", true);
          localStorage.setItem(
            "discordRoleInfo",
            JSON.stringify(res.data.data)
          );
        } else {
          this.setState({
            joinDiscordStatus: false,
          });
          localStorage.setItem("joinDiscord", false);
        }
      } else {
        notification.error({
          top: 70,
          duration: 1.5,
          placement: "bottomLeft",
          message: "Error",
          description: res.data.msg,
          onClick: () => {
            console.log("close");
          },
        });
      }
    });
  };
  getDiscordAuth = async (discordCode, solAddr) => {
    axios({
      url: "/refer/api/discord/auth",
      method: "post",
      data: { code: discordCode, solanaAddress: solAddr },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.data.code == 200) {
        let params = {
          solAddress: solAddr,
          discordInfo: res.data.data,
        };
        let localDCInfo = JSON.parse(localStorage.getItem("discordInfo")) || [];
        this.setState({
          discordInfo: res.data.data,
          joinDiscordStatus: localStorage.getItem("joinDiscord") || "false",
        });
        if (localDCInfo && localDCInfo.length == 0) {
          localDCInfo.push(params);
          localStorage.setItem("discordInfo", JSON.stringify(localDCInfo));
        } else {
          for (let i = 0; i < localDCInfo.length; i++) {
            if (localDCInfo[i].solAddress == solAddr) {
              return;
            }
          }
          localDCInfo.push(params);
          localStorage.setItem("discordInfo", JSON.stringify(localDCInfo));
        }
        localStorage.setItem("discordAuthStatus", true);
      } else {
        notification.error({
          top: 70,
          duration: 1.5,
          placement: "bottomLeft",
          message: "Error",
          description: res.data.msg,
          onClick: () => {
            console.log("close");
          },
        });
        localStorage.setItem("discordAuthStatus", false);
      }
      removeAllUrlParameters();
    });
  };
  ConnectTwitter = () => {
    let REDIRECT_URI = "";
    if (window.location.href.indexOf("?") > 0) {
      REDIRECT_URI = window.location.href.slice(
        0,
        window.location.href.indexOf("?")
      );
    } else {
      REDIRECT_URI = window.location.href.slice(0, window.location.href.length);
    }
    const codeVerifier = generateRandomString(30);
    localStorage.setItem("codeVerifier", codeVerifier);
    generateCodeChallenge(codeVerifier).then((res) => {
      const CODE_STR = new TextEncoder().encode(
        new Date().getTime().toString()
      );
      const STATE_STR = bs58.encode(CODE_STR);
      const CLIENT_ID = "MzU2SHNFVFpNbHVVSlVvY0ZPS0g6MTpjaQ";
      const SCOPE =
        "tweet.read users.read offline.access follows.read follows.write";
      const authUrl =
        "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=" +
        CLIENT_ID +
        "&redirect_uri=" +
        REDIRECT_URI +
        "&state=" +
        STATE_STR +
        "&scope=" +
        SCOPE +
        "&code_challenge=" +
        res +
        "&code_challenge_method=S256";
      window.location.href = authUrl;
    });
  };
  getTwitterAuth = async (codeStr, codeVerifier, solAddr) => {
    await axios({
      url: "/refer/twitter/api/info",
      method: "get",
      params: { code: codeStr, codeVerifier: codeVerifier, address: solAddr },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.data.code == 200) {
        if (res.data.data.user) {
          let tInfo = res.data.data.user;
          let params = {
            solAddress: solAddr,
            twitterInfo: tInfo,
          };
          let localTwitterInfo =
            JSON.parse(localStorage.getItem("twitterInfo")) || [];
          this.setState({
            twitterInfo: tInfo,
            following: true,
          });
          this.getTwitterTaskStatus(tInfo.id, solAddr);
          if (localTwitterInfo && localTwitterInfo.length == 0) {
            localTwitterInfo.push(params);
            localStorage.setItem(
              "twitterInfo",
              JSON.stringify(localTwitterInfo)
            );
          } else {
            for (let i = 0; i < localTwitterInfo.length; i++) {
              if (localTwitterInfo[i].solAddress == solAddr) {
                return;
              }
            }
            localTwitterInfo.push(params);
            localStorage.setItem(
              "twitterInfo",
              JSON.stringify(localTwitterInfo)
            );
          }
          removeAllUrlParameters();
        }
        localStorage.setItem("twitterAuthStatus", true);
      } else {
        notification.error({
          top: 70,
          duration: 1.5,
          placement: "bottomLeft",
          message: "Error",
          description: res.data.msg,
          onClick: () => {
            console.log("close");
          },
        });
        localStorage.setItem("twitterAuthStatus", false);
        removeAllUrlParameters();
      }
    });
  };
  getTwitterTaskStatus = async (twitterId, soladdress) => {
    await axios({
      url: `/refer/twitter/api/verify/${twitterId}/${soladdress}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          tweetCount: res.data.data.tweetCount,
          following: true,
        });
      } else if (res.data.code == 20217) {
        notification.error({
          top: 70,
          duration: 3,
          placement: "bottomLeft",
          message: "Error",
          description: "Token expired, please try connect again",
          onClick: () => {
            console.log("close");
          },
        });
        this.setState({
          twitterInfo: {},
        });
      } else {
        notification.error({
          top: 70,
          duration: 3,
          placement: "bottomLeft",
          message: "Error",
          description: res.data.msg,
          onClick: () => {
            console.log("close");
          },
        });
      }
    });
  };
  getTwitterTaskInfo = async (solAddr) => {
    await axios({
      url: "/refer/twitter/api/view",
      method: "get",
      params: {
        address: solAddr,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          tweetCount: res.data.data.tweetCount,
          following: res.data.data.following
        });
      } else {
        notification.error({
          top: 70,
          duration: 3,
          placement: "bottomLeft",
          message: "Error",
          description: res.data.msg,
          onClick: () => {
            console.log("close");
          },
        });
      }
    });
  };
  jumpToX = () => {
    const solAddr = localStorage.getItem("solanaAddress");
    const twitterId = this.state.twitterInfo.id;
    this.getTwitterTaskStatus(twitterId, solAddr);
    const twitterUrl =
      "https://x.com/intent/follow?original_referer=https%3A%2F%2Fwww.starpower.world%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Estarpowerworld&screen_name=starpowerworld";
    window.open(twitterUrl, "_blank");
  };
  postToX = () => {
    const refCode = this.state.registInfo.inviteCode;
    const solAddr = localStorage.getItem("solanaAddress");
    const twitterId = this.state.twitterInfo.id;
    let refURL =
      "https://www.starpower.world/wallet?ReferralCode=" + refCode + "%0A%0A";
    const text =
      "Join the @starpowerworld Genesis Drop!%0A" +
      "🚀Sign up now and participate in the airdrop using my referral code. Let's power up together!%0A" +
      "🔗Join here:%0A";
    const url = refURL;
    const hashtags = "Starpower,Airdrop,DePIN";
    const twitterPostUrl = `https://x.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`;
    window.open(twitterPostUrl, "_blank");
    this.getTwitterTaskStatus(twitterId, solAddr);
  };
  jumpToTel = () => {
    const telUrl = "https://t.me/starpowernetwork";
    window.open(telUrl, "_blank");
    const solAddr = localStorage.getItem("solanaAddress");
    if (
      this.state.telegramTask === true ||
      this.state.telegramTask === "true"
    ) {
      return;
    }
    axios({
      url: `/refer/api/telegram/insert`,
      method: "get",
      params: {
        solanaAddress: solAddr,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.data.code == 200) {
        let localData = JSON.parse(localStorage.getItem('telegramTask')) || [];
        localData.push({
          solAddress: solAddr,
          telegramTask: res.data.data,
        });
        if (res.data.data == true) {
          this.setState({
            telegramTask: true,
          });
          localStorage.setItem("telegramTask", JSON.stringify(localData));
        } else {
          notification.error({
            top: 70,
            duration: 2,
            placement: "bottomLeft",
            message: "Error",
            description: res.data.msg,
            onClick: () => {
              console.log("close");
            },
          });
        }
      } else {
        notification.error({
          top: 70,
          duration: 2,
          placement: "bottomLeft",
          message: "Error",
          description: res.data.msg,
          onClick: () => {
            console.log("close");
          },
        });
      }
    });
  };
  countdownUTC() {
    const endTime = new Date("2025-01-31").getTime();
    const now = new Date().getTime();
    const distance = endTime - now;
   
    // 如果倒计时已经结束，则返回0
    if (distance < 0) {
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
      return;
    }
   
    // 计算时间单位
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // 返回结果对象
    this.setState({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      checkEligibilityBtnDisable: false
    })
  };
  CheckEligibility = () => {
    window.location.assign('/airdrop')
    // this.props.history.replace("/airdrop");
  };
  render() {
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {this.state.step == 1 && (
          <div className="wallet-container1">
            <div className="wallet-title">
              <p>User On-boarding Airdrop</p>
              <p>Share 30,000,000 STARs</p>
            </div>
            <div className="wallet-addrInput">
              <input
                id="walletAddress"
                className="wallet-addrInput-input"
                type="text"
                onChange={this.checkAddr}
                placeholder="Enter your wallet address"
              />
              {this.state.registed ? (
                <Button
                  className="wallet-addrInput-submit registed"
                  type="primary"
                  loading={this.state.btnLoading}
                  onClick={this.goToProfile}
                >
                  Go to profile
                </Button>
              ) : (
                <Button
                  className={
                    this.state.canReg
                      ? "wallet-addrInput-submit registed"
                      : "wallet-addrInput-submit"
                  }
                  type="primary"
                  loading={this.state.btnLoading}
                  onClick={this.registWallet}
                >
                  Register
                </Button>
              )}
            </div>
            {this.state.checkPass && (
              <div className="check-pass">
                Solana address is already registered.
              </div>
            )}
            <div className="wallet-message">
              <p>
                Connect your wallet and use referral code to boost your rank to
                get more rewards.
              </p>
            </div>
          </div>
        )}
        {this.state.step == 2 && (
          <div className="wallet-container2">
            <div className="wallet-card1">
              <div className="wallet-card1-title">REGISTER SUCCEED</div>
              <div className="wallet-card1-info">
                <p className="card1-info-title">Your Rank Is</p>
                <p className="card1-info-rank">
                  #{this.state.registInfo ? this.state.registInfo.rank : "-"}
                </p>
                <p className="card1-info-estimated">
                  Estimated Rank After Verification: #
                  {this.state.estimatedRank ? this.state.estimatedRank : "-"}
                </p>
                <p className="card1-info-message">
                  Verifying your wallet ownership can boost your ranking,
                  (Simply sign, no need for transactions)
                </p>
              </div>
              <div className="wallet-card1-option">
                <Button
                  className="card1-option-btn"
                  type="primary"
                  onClick={() => localStorage.setItem("walletVisible", true)}
                >
                  Connect Wallet
                </Button>
                <Button
                  className="card1-option-skip"
                  type="primary"
                  onClick={this.goToProfile}
                >
                  Skip
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.state.step == 3 && (
          <div className="wallet-container3">
            {/* <div className="wallet-airdrop">
              <div className="wallet-airdrop-top">
                <span>Countdown For Airdrop Claim:</span>
                <span>
                  {this.state.days}<span className="wallet-airdrop-top-desc">days</span>
                  {this.state.hours}<span className="wallet-airdrop-top-desc">Hours</span>
                  {this.state.minutes}<span className="wallet-airdrop-top-desc">Mins</span>
                  {this.state.seconds}<span className="wallet-airdrop-top-desc">Secs</span>
                </span>
              </div>
              <div className="wallet-airdrop-bottom">
                <p><span>Claiming Rewards</span><br/>
                <span>Check your eligibility, verify allocations, and access claims only through the official <u>https://www.starpower.world</u> website.</span>
                </p>
                <p>
                  <button disabled={this.state.checkEligibilityBtnDisable} onClick={this.CheckEligibility}>Check Eligibility</button>
                </p>
              </div>
            </div> */}
            <div className="wallet-card2-top">
              <div className="card2-top-base">
                <span>
                  {this.state.registInfo
                    ? this.state.registInfo.solAddress &&
                      this.state.registInfo.solAddress.length > 20
                      ? addrSubStr(this.state.registInfo.solAddress)
                      : this.state.registInfo.solAddress
                    : "-"}
                </span>
                <span>Invited by</span>
                <span>
                  {this.state.registInfo
                    ? this.state.registInfo.invitedCode
                      ? this.state.registInfo.invitedCode +
                        " #" +
                        this.state.registInfo.rankRefer
                      : "-----"
                    : "------"}
                </span>
              </div>
              <div className="card2-top-info">
                <div className="card2-info-ranking">
                  <p>Ranking</p>
                  <p>
                    #{this.state.registInfo ? this.state.registInfo.rank : "-"}
                  </p>
                </div>
                <div className="card2-info-total">
                  <p>Total Referral</p>
                  <p>
                    {this.state.registInfo
                      ? this.state.registInfo.totalReferral
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            {localStorage.getItem("solanaAddress") && (
              <div className="wallet-card2-social">
                <div className="card2-social-title">Socials</div>
                <div className="card2-social-info">
                  <div className="social-info-twitter">
                    <div className="social-twitter-title">
                      X(Twitter)
                      <span>
                        {this.state.twitterInfo &&
                        this.state.twitterInfo.username
                          ? "@" + this.state.twitterInfo.username
                          : ""}
                      </span>
                    </div>
                    <div className="social-twitter-info">
                      {this.state.twitterInfo && this.state.twitterInfo.id ? (
                        <div className="follow-twitter" onClick={this.jumpToX}>
                          Follow <span>@Starpower</span> on X(Twitter)
                        </div>
                      ) : (
                        <button
                          className="connect-twitter"
                          onClick={this.ConnectTwitter}
                        >
                          Connect your X(Twitter)
                        </button>
                      )}
                      {this.state.twitterInfo && this.state.twitterInfo.id && (
                        <div className="twitter-info-right">
                          <div className="twitter-right-option">
                            <div className="twitter-right-info">
                              Total Referral Tweet you've share :&nbsp;
                              <span>
                                {parseInt(this.state.tweetCount) > 0
                                  ? parseInt(this.state.tweetCount)
                                  : 0}
                              </span>
                            </div>
                            <button onClick={this.postToX}>
                              Referral Tweet
                            </button>
                          </div>

                          <p className="twitter-right-desc">
                            One effective share per day
                            <Tooltip
                              className="tool-tip"
                              placement="bottom"
                              title={this.state.toolTipText}
                            >
                              <Icon type="info-circle" />
                            </Tooltip>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="social-info-other" style={{opacity: (this.state.following)? '1': '0.3'}}>
                    <div className="social-other-title">
                      Discord
                      <span>
                        {this.state.discordInfo &&
                        this.state.discordInfo.globalName
                          ? "@" + this.state.discordInfo.globalName
                          : ""}
                      </span>
                    </div>
                    <div className="social-other-info">
                      {this.state.discordInfo &&
                      this.state.discordInfo.userId ? (
                        this.state.joinDiscordStatus == false ||
                        this.state.joinDiscordStatus == "false" ? (
                          <button onClick={this.joinDiscord}>
                            Join Server
                          </button>
                        ) : (
                          <button disabled={!this.state.following} onClick={this.getDiscordRole}>Refresh</button>
                        )
                      ) : (
                        <button disabled={!this.state.following} onClick={this.ConnectDiscord}>Connect</button>
                      )}
                      <div className="other-info-dc">
                        {this.state.discordRoleInfo &&
                        this.state.discordRoleInfo.length > 0 ? (
                          this.state.discordRoleInfo.map((item, index) => {
                            if (index < 5) {
                              if (
                                index ==
                                this.state.discordRoleInfo.length - 1
                              ) {
                                return (
                                  <span
                                    key={index}
                                    style={{ color: item.color }}
                                  >
                                    {item.name}
                                  </span>
                                );
                              } else {
                                return (
                                  <span key={index}>
                                    <span style={{ color: item.color }}>
                                      {item.name}
                                    </span>
                                    &nbsp;/&nbsp;
                                  </span>
                                );
                              }
                            } else if (index == 5) {
                              return <span key={index}>...</span>;
                            }
                          })
                        ) : (
                          <span>Join Starpower Network to boost your ranking</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="social-info-other" style={{opacity: (this.state.following && this.state.joinDiscordStatus)? '1': '0.3'}}>
                    <div className="social-other-title">Telegram</div>
                    {(this.state.telegramTask) ? (
                      <button className="social-other-tel" onClick={this.jumpToTel}>
                      View&nbsp;&nbsp;<span>@Starpower Network</span><Icon className="other-tel-icon" type="check-circle" />
                    </button>
                    ) : (<button disabled={!this.state.following && !this.state.joinDiscordStatus} className="social-other-tel" onClick={this.jumpToTel}>
                      Join&nbsp;&nbsp;<span>@Starpower Network</span>
                    </button>)

                    }
                    
                  </div>
                </div>
              </div>
            )}
            {localStorage.getItem("solanaAddress") && (
              <div className="wallet-card2-middle" style={{opacity: (this.state.following && this.state.joinDiscordStatus)? '1': '0.3'}}>
                <div className="card2-middle-title">
                  Bonus Reward
                  <a
                    href="https://starpower.gitbook.io/https-starpower.gitbook.io-documentation"
                    target="_blank"
                  >
                    <Icon
                      className="middle-title-icon"
                      type="question-circle"
                    />
                  </a>
                </div>
                <div className="card2-middle-info">
                  <div className="card2-middle-card">
                    <div className="middle-card">
                      <p>
                        Pioneer Pass
                        <br />
                        Badge Holders
                      </p>
                      <p>Activated*{this.state.NFTCount || 0}</p>
                      <p>
                        {this.state.NFTCount > 0 ? (
                          <Icon
                            className="middle-card-icon-right"
                            type="check"
                          />
                        ) : (
                          <Icon
                            className="middle-card-icon-wrong"
                            type="close-circle"
                          />
                        )}
                      </p>
                    </div>
                    <p className="card2-middle-Link">
                      <a
                        href="https://starpower.gitbook.io/https-starpower.gitbook.io-documentation/pioneer-pass-nft/nft-activation"
                        target="_blank"
                      >
                        How to activate Pioneer NFT →
                      </a>
                    </p>
                  </div>
                  <div className="card2-middle-card">
                    <div className="middle-card">
                      <p>
                        Hardware
                        <br />
                        Holders
                      </p>
                      <p>Starplug*{this.state.DevicesCount || 0}</p>
                      <p>
                        <button
                          disabled={!this.state.following && !this.state.joinDiscordStatus}
                          className="card2-middle-btn"
                          type="primary"
                          onClick={this.getDevicesCount}
                        >
                          Verify
                        </button>
                      </p>
                    </div>
                    <p className="card2-middle-Link">
                      <a
                        href="https://starpower-market.myshopify.com/products/starplug"
                        target="_blank"
                      >
                        Buy one and bind now →
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {localStorage.getItem("solanaAddress") ? (
              <div className="wallet-card2-bottom" style={{opacity: (this.state.following && this.state.joinDiscordStatus)? '1': '0.3'}}>
                <div className="card2-bottom-title">
                  <span>Referrals</span>
                  {this.state.registInfo.invitedCode && (
                    <p className="card2-bottom-right-title">
                      Share Your Referral{" "}
                      <Icon
                        type="copy"
                        onClick={this.copyRefCode}
                        className="right-title-copy"
                        theme="filled"
                      />
                    </p>
                  )}
                </div>
                <div className="card2-bottom-info">
                  {!this.state.registInfo.invitedCode && (
                    <div className="card2-bottom-left">
                      <p className="card2-bottom-left-title">
                        Submit Referral Code
                      </p>
                      <p className="card2-bottom-left-input">
                        <input
                          readOnly={
                            this.state.refCode &&
                            this.state.refCode.length > 0 &&
                            this.state.refCode !=
                              this.state.registInfo.inviteCode
                              ? true
                              : false
                          }
                          id="refCode"
                          className="wallet-addrInput-input"
                          type="text"
                          maxLength="6"
                          onInput={this.handleInput}
                          placeholder="------"
                        />
                      </p>
                      <p className="card2-bottom-left-msg">
                        Enter the code to boost your ranking.
                        <br />
                        *Once you submit the referral code, it can't be changed.
                      </p>
                      <p className="card2-bottom-left-option">
                        <button
                          disabled={!this.state.following && !this.state.joinDiscordStatus}
                          className="card2-option-btn"
                          type="primary"
                          onClick={this.submitReferral}
                        >
                          Submit
                        </button>
                      </p>
                    </div>
                  )}
                  <div
                    className={
                      !this.state.registInfo.invitedCode
                        ? "card2-bottom-right"
                        : "card2-bottom-center"
                    }
                  >
                    {!this.state.registInfo.invitedCode && (
                      <p className="card2-bottom-right-title">
                        Share Your Referral{" "}
                        <Icon
                          type="copy"
                          onClick={this.copyRefCode}
                          className="right-title-copy"
                          theme="filled"
                        />
                      </p>
                    )}
                    <p className="card2-bottom-right-input">
                      <input
                        readOnly
                        id="myRefCode"
                        className="wallet-addrInput-input"
                        type="text"
                        maxLength="6"
                        value={
                          this.state.registInfo
                            ? this.state.registInfo.inviteCode
                            : "------"
                        }
                      />
                    </p>
                    <div className="card2-bottom-right-list">
                      <p>
                        <span>Friends you invited</span>{" "}
                        <span>
                          {this.state.invitedListInfo
                            ? this.state.invitedListInfo.total
                            : "0"}
                        </span>
                      </p>
                      {this.state.invitedListInfo &&
                      this.state.invitedListInfo.data &&
                      this.state.invitedListInfo.data.length > 0 ? (
                        this.state.invitedListInfo.data.map((item) => {
                          return (
                            <p key={item.solanaAddress}>
                              <span>{item.solanaAddress}</span>
                              <span>#{item.rank}</span>
                            </p>
                          );
                        })
                      ) : (
                        <p>
                          <span>----------</span>
                          <span>#-----</span>
                        </p>
                      )}
                      {this.state.invitedListInfo &&
                      this.state.invitedListInfo.total &&
                      this.state.invitedListInfo.total > 10 ? (
                        <Pagination
                          class="list-pagination"
                          defaultCurrent={1}
                          total={this.state.invitedListInfo.total}
                          onChange={this.changePage}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.invitedListInfo &&
                    this.state.invitedListInfo.total &&
                    this.state.invitedListInfo.total > 0 ? (
                      ""
                    ) : (
                      <p className="card2-bottom-right-msg">
                        Copy your code and share the link with your friends over
                        twitter or any other social platform.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="wallet-card2-bottom">
                <div className="card2-bottom-verify">
                  <p>Verify your wallet to boost your ranking!</p>
                  <div className="bottom-verify-option">
                    <button
                      className="bottom-verify-btn"
                      type="primary"
                      onClick={() =>
                        localStorage.setItem("walletVisible", true)
                      }
                    >
                      Connect Wallet
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
